import React from "react";
import { Wrapper } from "../components/globalElements";
import Layout from "../components/Layout/Layout";
import { TextSplit } from "components";
import { LoginStyled } from "../styles/login.styles";
import LoginForm from "../components/Onboard/LoginForm";
import OnboardFooter from "../components/Onboard/OnboardFooter";

const Login = () => {
	return (
		<Layout title="Login Page" fullScreen={true} footer={false}>
			<Wrapper style={{ marginBottom: "5rem" }}>
				<TextSplit>
					<LoginStyled>
						<h1 className="heading">Portal Login</h1>
						<p className="paragraph">
							The Amalfi Tiles Architect & Design portal is
							invite-only. If you are a registered Architect or
							Interior Designer please get in contact with our
							team to learn more.
						</p>

						<LoginForm />
						<div className="middle-line"></div>

						<OnboardFooter />
					</LoginStyled>
				</TextSplit>
			</Wrapper>
		</Layout>
	);
};

export default Login;
