import React from "react";
import { Gap } from "../../styles/login.styles";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import { logInWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { toast } from "react-toastify";

const LoginForm = () => {
	const validate = (values) => {
		let errors = {};
		if (!values.email) {
			errors.email = "⋆Required";
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
		) {
			errors.email = "Invalid email format";
		}
		if (!values.password) {
			errors.password = "⋆Required";
		} else if (values.password.length < 8) {
			errors.password = "Password must be 8 characters long";
		}

		return errors;
	};

	const initialValues = {
		email: "",
		password: ""
	};

	const onSubmit = async (values, onSubmitProps) => {
		console.log("submit working");
		const res = await logInWithEmailAndPassword(
			values.email,
			values.password
		);
		if (res) {
			navigate("/welcome-back");
			toast.success("User Logged In Successfully!");
			onSubmitProps.resetForm();
		}
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validate
	});
	return (
		<>
			<div>
				<label htmlFor="email">Email</label>
				<input
					className={"input-success"}
					type="email"
					name="email"
					value={formik.values.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>

				{formik.touched.email && formik.errors.email ? (
					<div className="error-msg">{formik.errors.email}</div>
				) : null}
			</div>

			<div>
				<label htmlFor="password">Password</label>
				<input
					type="password"
					className={"input-success"}
					name="password"
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>

				{formik.touched.password && formik.errors.password ? (
					<div className="error-msg">{formik.errors.password}</div>
				) : null}
			</div>

			<Gap mt={"14px"}></Gap>

			<button
				className="button-filled"
				disabled={!(formik.isValid && formik.dirty)}
				type="submit"
				onClick={formik.submitForm}
			>
				Login
			</button>
			<button
				className="button-unfilled"
				onClick={() => navigate("/forgot-password")}
			>
				Forgot Password
			</button>
		</>
	);
};

export default LoginForm;
